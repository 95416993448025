.not-found {
  font-size: 2rem;
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warning {
  color: yellow;
  margin-right: 1%;
}
