.recipesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 4%;
}

.recipesTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.filterRecipes {
  width: 68%;
}

.cardRecipes {
  width: 68%;
  display: grid;
  grid-template-columns: 49% 49%;
  align-items: center;
  justify-items: center;
  gap: 2%;
}

.cardRecipe {
  width: 100% !important;
  height: 100% !important;
  font-size: 1rem !important;
}

.cardRecipe p {
  font-size: 1rem !important;
}

.recipeDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .recipesTitle {
    flex-direction: column;
  }

  .filterRecipes {
    width: 96%;
  }

  .cardRecipes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 96%;
  }

  .cardRecipe {
    height: 100%;
  }
}
