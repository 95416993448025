.recipeContainer {
  margin: 4%;
}

.recipeInfo {
  display: flex;
  gap: 10px;
}

.recipeInfo p {
  display: flex;
  align-items: center;
  gap: 2px;
}

.backToRecipesDiv {
  width: 60%;
  margin: 0 auto;
}

.backToRecipes {
  width: 50%;
}

.cardOneRecipe {
  width: 60%;
}

@media screen and (max-width: 800px) {
  .cardOneRecipe {
    width: 96%;
  }

  .backToRecipesDiv {
    width: 96%;
  }

  .recipeInfo {
    flex-direction: column;
    gap: 10px;
  }
}
